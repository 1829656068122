import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Collapse, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import NavContainer from 'components/NavContainer/NavContainer';
import { useAppContext } from 'contexts/AppContext';
import './Header.scss';

function Header({ onClickSsoLogout }) { 
    const [shouldDisplayMenu, setShouldDisplayMenu] = useState(false);
    const { isMobile } = useAppContext();
    const hideMenu = () => {
        setShouldDisplayMenu(false);
    };

    return (
        <React.Fragment>
            <Navbar 
                bg='primary' variant='dark'
                className={`${isMobile ? 'navbar-mobile' : 'navbar-desktop'}`}
                id='navbar-top'
            >
                <Link 
                    className='max-width'
                    id='navbar-title-link' 
                    onClick={hideMenu}
                    to='/' 
                >
                    <Navbar.Brand>Pay</Navbar.Brand>
                </Link>
                <Nav className='ml-auto'> 
                    <Button
                        className={`d-lg-none menu-button-${shouldDisplayMenu ? 'white' : 'blue'}`}
                        id='menu-button'
                        onClick={() => setShouldDisplayMenu(!shouldDisplayMenu)}
                    > 
                        Menu
                    </Button> 
                    <Button 
                        className='d-none d-lg-inline'
                        onClick={onClickSsoLogout} 
                    >
                        Logout
                    </Button>
                </Nav>
            </Navbar>
            <Collapse className='d-lg-none pb-4 px-3' in={shouldDisplayMenu}>
                <Card className='mb-5 pt-2' id='expanded-menu'>
                    <NavContainer handleHideMenu={hideMenu}/>
                    <Button 
                        block 
                        className='mt-2'
                        onClick={onClickSsoLogout} 
                    >
                        Logout
                    </Button>
                </Card>
            </Collapse>
        </React.Fragment>
    );
};

Header.propTypes = {
    onClickSsoLogout: PropTypes.func.isRequired,
};

export default Header;