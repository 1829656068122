/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, {createContext, useContext, useEffect, useState, useMemo} from 'react';
import useAuthentication from 'hooks/useAuthentication';

export const useAppContext = () => useContext(AppContext);
const AppContext = createContext();

function AppProvider(props) {
    const [allowAppAccess, setAllowAppAccess] = useState(false);
    const [currentUserInfo, setCurrentUserInfo] = useState({});
    const [errorDetails, setErrorDetails] = useState({
        errorMessage: null,
        displayType: null, 
    });
    const [isMobile, setIsMobile] = useState();
    const [testEmployeeId, setTestEmployeeId] = useState();

    const authLogLevel = process.env.REACT_APP_OAUTH_LOG_LEVEL; //'DEBUG', 'INFO';
    const authEnableEvents = true;

    const checkIfMobile = () => {
        const maxMobileWidth = 991;
        if (window.innerWidth <= maxMobileWidth) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    const isTokenExpired = async () => {
        const expiresAt = localStorage.expiration_time;
        const currentTime = Math.floor(new Date().getTime() / 1000);
        if (currentTime > expiresAt) {
            await authApi.login(window.location.pathname);
            return true;
        };
        return false;
    };

    const resetErrorDetails = () => {
        setErrorDetails({
            displayType: undefined,
            detailsMessage: undefined,
        });
    };

    const shouldLogAuthDebugMessages = () => {
        return process.env.REACT_APP_OAUTH_LOG_LEVEL === 'DEBUG';
    };

    const updateCurrentUserInfo = (newUserInfo) => {
        const employeeId = newUserInfo?.employeeId;
        const employeeIdNoLeadingZeros = employeeId ? +newUserInfo.employeeId : '';
        
        const newUserInfoNoLeadingZeros = {...newUserInfo, employeeId: employeeIdNoLeadingZeros};
        setCurrentUserInfo(newUserInfoNoLeadingZeros);
    };

    const updateTestEmployeeId = (newTestEmployeeId) => {
        setTestEmployeeId(newTestEmployeeId);
        sessionStorage.testEmployeeId = newTestEmployeeId;
    };
    
    const [authState, authApi] = useAuthentication(updateCurrentUserInfo, authLogLevel, authEnableEvents);
    const {isAppAuthSequenceComplete, isManagerReadyWithUserData, wasAuthRedirectCalled} = authState;

    const handleSsoLogout = () => {
        authApi.logout();
    };

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        if (sessionStorage.testEmployeeId) {
            const cachedTestEmployeeId = sessionStorage.testEmployeeId;
            setTestEmployeeId(cachedTestEmployeeId);
        };
    }, []);

    useEffect(() => {
        if (isAppAuthSequenceComplete) {
            if (shouldLogAuthDebugMessages()) {
                // eslint-disable-next-line no-console
                console.log('%c[App.jsx] auth sequence complete, moving to app startup (step 2)', 'color: #f97c00');
            }

            setAllowAppAccess(true);
        }
    }, [isAppAuthSequenceComplete]);

    useEffect(() => {
        if (isManagerReadyWithUserData && !wasAuthRedirectCalled) {
            if (shouldLogAuthDebugMessages()) {
                // eslint-disable-next-line no-console
                console.log(`%c[App.jsx] APP new startup, logging in...`, 'color: #f97c00');
            }
            
            const currPathName = window.location.pathname;
            localStorage.setItem('ePaysStartupRoute', currPathName);
            authApi.login(currPathName);
        }
    }, [isManagerReadyWithUserData]);

    const contextValue = useMemo(() => ({
        allowAppAccess,
        currentUserInfo,
        errorDetails,
        handleSsoLogout,
        isAppAuthSequenceComplete,
        isTokenExpired,
        isMobile,
        resetErrorDetails,
        setAllowAppAccess,
        setErrorDetails,
        testEmployeeId,
        updateCurrentUserInfo,
        updateTestEmployeeId,
    }), [
        allowAppAccess,
        currentUserInfo,
        errorDetails,
        handleSsoLogout,
        isAppAuthSequenceComplete,
        isTokenExpired,
        isMobile,
        resetErrorDetails,
        setAllowAppAccess,
        setErrorDetails,
        testEmployeeId,
        updateCurrentUserInfo,
        updateTestEmployeeId,
    ]);

    return (
        <AppContext.Provider value={contextValue}>
            {props.children}
        </AppContext.Provider>
    );
};

export {AppContext, AppProvider};