/* eslint-disable react/no-unused-prop-types */
import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {useAppContext} from 'contexts/AppContext';
import './SessionData.scss';

function SessionData() {
    const [currentDateTime, setCurrentDateTime] = useState();
    const {currentUserInfo} = useAppContext();

    const updateCurrentDateTime = () => {
        const timeZoneRegex = /\(([\w\s]+)\)/; // Look for "(", any words (\w) or spaces (\s), and ")"
        const date = new Date();
        const timeZoneArr = timeZoneRegex.exec(date?.toString()); 
        setCurrentDateTime(
            `${format(date, 'Pp')} ${timeZoneArr?.[1]}`
        );
    };

    useEffect(() => {
        const initializeDateTimeProcess = () => {
            updateCurrentDateTime();
            setInterval(() => {
                updateCurrentDateTime();
            }, 60000);
        };

        initializeDateTimeProcess();
    });

    return (
        currentUserInfo !== undefined && (
            <div className="marginTop text-primary">
                <div>
                    <span className="user-full-name mr-3">
                        {currentUserInfo.fullName}
                    </span>
                    <span className="user-id mr-3">
                        {currentUserInfo.employeeId}
                    </span>
                </div>
                <div className="timestamp">{currentDateTime}</div>
            </div>
        )
    );
};

export default SessionData; 