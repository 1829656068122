/* eslint-disable no-console */
import Axios from 'axios';
import messageList from 'utilities/messageConfig';
import {isTierDevelopmentOrStaging} from 'utilities/checkCurrentTier';
const baseURL = new URL(process.env.REACT_APP_EPAYS3_API_URL);
const axiosInstance = Axios.create({
    baseURL: baseURL.href,
    withCredentials: true,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
    },
});

const addBearerToken = (config) => { 
    const modifiedConfig = config; 
    const token = localStorage.access_token; 
    if (token) { 
        modifiedConfig.headers.Authorization = `Bearer ${token}`; 
    } 
    return modifiedConfig; 
}; 

const addTestEmployeeIdHeaderIfCached = (config) => {
    const configWithHeader = config;
    if (sessionStorage.testEmployeeId && isTierDevelopmentOrStaging()) {
        configWithHeader.headers.get.testEmployeeId = sessionStorage.testEmployeeId;
        configWithHeader.headers.post.testEmployeeId = sessionStorage.testEmployeeId;
    }
    return configWithHeader;
};

const checkIfResponseIsBlob = async (error) => {
    if (error.response?.request?.responseType === 'blob') {
        const resText = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('abort', reject);
            reader.addEventListener('error', reject);
            reader.addEventListener('loadend', () => {
                resolve(reader.result);
            });
            reader.readAsText(error.response.data);
        });
        const resJson = JSON.parse(resText);
        return {
            errorMessage: resJson?.displayMessage,
            epaysCode: resJson?.code,
            supportId: resJson?.supportId,
        };
    }
    
    const errorData = error.response.data;
    return {
        errorMessage: errorData?.displayMessage,
        epaysCode: errorData?.code,
        supportId: errorData?.supportId,
    };
};

// Another interceptor is present in useTimeout hook
axiosInstance.interceptors.request.use(config => {
    addBearerToken(config); 
    addTestEmployeeIdHeaderIfCached(config);

    return config;
}, (error) => {
    console.log(`interceptor request catch block/error: ${JSON.stringify(error)}`);
});

axiosInstance.interceptors.response.use(response => {
    console.log(`from interceptor ${JSON.stringify(response)}`);
    return response;
}, async (error) => {
    let errorResponse = {
        displayType: 'error',
        errorMessage: messageList.unexpectedError,
        redirect: false,
        isError: true,
    };

    if (!error?.response) {
        return Promise.reject(errorResponse);
    };
    
    const {errorMessage, epaysCode, supportId} = await checkIfResponseIsBlob(error);
    errorResponse = {
        ...errorResponse,
        epaysCode,
        errorMessage,
        supportId,
        errorResponseStatusCode: error.response.status,
    };

    if (error.response.status === 401) {
        errorResponse.redirect = true;
        errorResponse.errorMessage = messageList.unauthorized;
    };
    
    if (epaysCode === 3002) {
        errorResponse.redirect = true;
    };

    return Promise.reject(errorResponse);
});

export default axiosInstance;