import axiosInstance from 'services/axiosConfig';

const getExistingBankAccounts = async () => {
    const url = `/directdeposit/employeebankdata`;
    const ddpOutageCode = 2032;

    return axiosInstance.get(url)
        .then(response => {
            return {
                accountData: response.data, 
                isError: false,
            };
        }).catch(errorResponse => {
            if (errorResponse?.epaysCode === ddpOutageCode) {
                return {
                    isError: false,
                    message: errorResponse?.errorMessage, 
                    isOutage: true,
                };
            } 
            return errorResponse;
        });
};

export default getExistingBankAccounts;