const isTierDevelopmentOrStaging = () => {
    return process.env.REACT_APP_EPAYS3_TIER === 'development' ||
        process.env.REACT_APP_EPAYS3_TIER === 'staging';
};

const isTierProduction = () => {
    return process.env.REACT_APP_EPAYS3_TIER === 'production';
};


export {isTierDevelopmentOrStaging, isTierProduction};