const withLoading = async (localLoadingHandler, localLoadingState, dataHandler) => {
    if (localLoadingState) {
        await dataHandler();
    } else {
        localLoadingHandler(true);
        await dataHandler();
    }
    localLoadingHandler(false);
};

export default withLoading;