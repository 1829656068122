import PropTypes from 'prop-types';
import React from 'react';

import Help from 'components/NavContainer/Help/Help';
import Navigation from 'components/NavContainer/Navigation/Navigation';

function NavContainer({ handleHideMenu = () => {} }) {
    return (
        <>
            <div>
                <Navigation onClickHideMenu={handleHideMenu} />
            </div>
            <div className="my-3">
                <Help />
            </div>
        </>
    );
}

NavContainer.propTypes = {
    handleHideMenu: PropTypes.func,
};

export default NavContainer;
