import React from 'react';
import { Route, Routes as RouterRoutes, Navigate } from 'react-router-dom';
import { useAppContext } from 'contexts/AppContext';
import { DirectDepositProvider } from 'contexts/DirectDepositContext';
import GeneralErrorPage from 'components/GeneralErrorPage/GeneralErrorPage';
import routes from 'routes/routes';

function AppRoutes() {
    const { allowAppAccess } = useAppContext();
    return (
        <div className='app-routing-components'>
            {allowAppAccess ? (
                <RouterRoutes>
                    {routes.map((route) => {
                        const { path, element, subRoutes, title } = route;

                        if (title === 'Direct Deposit') {
                            return (
                                <Route
                                    key={path}
                                    path={path}
                                    element={
                                        <DirectDepositProvider>
                                            {element}
                                        </DirectDepositProvider>
                                    }
                                />
                            );
                        }

                        if (subRoutes && subRoutes.length > 0) {
                            return (
                                <Route
                                    key={path}
                                    path={path}
                                    element={React.cloneElement(element, { subRoutes })}
                                >
                                    {subRoutes.map((subRoute) => {
                                        const {
                                            path: subPath,
                                            element: subElement,
                                            index: isIndex,
                                        } = subRoute;
                                        return (
                                            <Route
                                                key={subPath || 'index'}
                                                path={subPath}
                                                element={subElement}
                                                index={isIndex}
                                            />
                                        );
                                    })}
                                </Route>
                            );
                        }

                        return (
                            <Route key={path} path={path} element={element} />
                        );
                    })}
                </RouterRoutes>
            ) : (
                <RouterRoutes>
                    <Route path='/error' element={<GeneralErrorPage />} />
                    <Route path='*' element={<Navigate to='/error' />} />
                </RouterRoutes>
            )}
        </div>
    );
};

export default AppRoutes;
