import axiosInstance from 'services/axiosConfig';

const getCurrentAnnouncement = async () => {
    const url = `/shared/getcurrentadminbanner`;
    return axiosInstance.get(url)
        .then(response => { 
            return {                
                searchAdminBanner: response.data, 
                isError: false,
            };
        }).catch(errorResponse => {
            return errorResponse;
        });
};

export default getCurrentAnnouncement;