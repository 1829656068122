/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Error({ errorMessage, displayType, supportId }) {
    if (!displayType) {
        return null;
    }

    const displayTypeLower = displayType.toLowerCase();

    const errorDisplayType = 'error';
    const warningDisplayType = 'warning';

    const errorMessageWithSupport = `${errorMessage} - Support ID: ${supportId}.`;

    const errorBody = supportId ? errorMessageWithSupport : errorMessage;

    return (
        (displayTypeLower === errorDisplayType || displayTypeLower === warningDisplayType) && (
            <Row
                className={`border-2px mt-2 py-2 font-size-22 no-gutters ${
                    displayTypeLower === errorDisplayType ? 'border-danger' : 'border-warning'
                }`}
            >
                <Col
                    xs={2}
                    sm={1}
                    className={`text-center align-self-center ${
                        displayTypeLower === errorDisplayType
                            ? 'icon-warning text-danger'
                            : 'icon-information text-warning'
                    }`}
                />
                <Col
                    className={`pr-2 align-self-center font-size-16 ${
                        displayTypeLower === errorDisplayType ? 'text-danger' : 'text-warning'
                    }`}
                >
                    <div className="errorBody">{errorBody}</div>
                    {supportId && (
                        <div className="supportMessage">
                            Please include this support ID if you require assistance
                        </div>
                    )}
                </Col>
            </Row>
        )
    );
}

Error.propTypes = {
    displayType: PropTypes.string,
    errorMessage: PropTypes.string,
    supportId: PropTypes.string,
};

export default Error;
