import axiosInstance from 'services/axiosConfig';

const postEditedAccounts = async (data) => {
    const url = `/directdeposit/submitemployeebankdata`;
    return axiosInstance
        .post(url, data)
        .then((response) => {
            return {
                submitError: response.data,
                isError: false,
            };
        })
        .catch((errorResponse) => {
            return errorResponse;
        });
};

export default postEditedAccounts;
