import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import routes from 'routes/routes';
import routesExternal from 'routes/routesExternal';

import { useAppContext } from 'contexts/AppContext';

import './Navigation.scss';

function Navigation({ onClickHideMenu = () => {} }) {
    const { setErrorDetails } = useAppContext();

    const resetErrorAndHideMenu = () => {
        onClickHideMenu();
        setErrorDetails({
            displayType: undefined,
            detailsMessage: undefined,
        });
    };

    const navigationLinks = routes.map((route) => {
        const subMenuItem =
            route.subRoutes &&
            route.subRoutes.map((subRoute) => {
                if (subRoute?.showInNavMenu) {
                    return (
                        <NavLink
                            className={({ isActive }) =>
                                `h3 pt-2 ml-5 navigation-links ${
                                    isActive ? 'is-active' : ''
                                }`
                            }
                            to={subRoute.path}
                            key={`subroute-${route.path}-${subRoute.path}`}
                            onClick={onClickHideMenu}
                        >
                            {subRoute.title}
                        </NavLink>
                    );
                }

                return null;
            });

        if (route.showInNavMenu) {
            return (
                <div key={route.path}>
                    <div className="h3 mb-0 py-2 cursor-pointer">
                        <NavLink
                            className={({ isActive }) =>
                                `ml-3 navigation-links ${
                                    isActive ? 'is-active' : ''
                                }`
                            }
                            to={
                                route.title === 'Pay Statements'
                                    ? '/paystatements/'
                                    : route.path
                            }
                            onClick={resetErrorAndHideMenu}
                        >
                            {route.title}
                        </NavLink>
                    </div>
                    {subMenuItem}
                    <hr />
                </div>
            );
        }

        return null;
    });

    const navigationLinksExternal = routesExternal.map((route, index) => {
        if (route.showInNavMenu) {
            return (
                <div key={`nav-${index + 1}`}>
                    <div className="h3 mb-0 py-2 cursor-pointer">
                        <a
                            className="ml-3 navigation-links"
                            onClick={onClickHideMenu}
                            href={route.path}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {route.title}
                        </a>
                    </div>
                    <hr />
                </div>
            );
        }

        return null;
    });

    return (
        <div className="ml-6 mt-4">
            {navigationLinks}
            {navigationLinksExternal}
        </div>
    );
}

Navigation.propTypes = {
    onClickHideMenu: PropTypes.func,
};

export default Navigation;
