import React, { useContext } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { AppContext } from 'contexts/AppContext';
import './Footer.scss';
import { isTierDevelopmentOrStaging } from 'utilities/checkCurrentTier';

function Footer({ loggedInUser = '' }) { // Default parameter assigned
    const { currentUserInfo, testEmployeeId } = useContext(AppContext);
    const currentEmployeeId = get(currentUserInfo, 'employeeId', ''); 

    return (
        isTierDevelopmentOrStaging() && (
            <Container className="app-footer">
                <Navbar
                    fixed="bottom"
                    variant="light"
                    className="d-flex justify-content-between"
                >
                    <div>
                        <span className="footer-text">
                            Logged in as: {currentEmployeeId}
                        </span>
                    </div>
                    <div>
                        <span className="footer-text">
                            Test user:
                            <span className="text-danger font-weight-bold pl-1">
                                {testEmployeeId}
                            </span>
                        </span>
                    </div>
                </Navbar>
            </Container>
        )
    );
}

Footer.propTypes = {
    loggedInUser: PropTypes.string,
};


export default Footer;
