/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import queryString from 'query-string';

import { isTierDevelopmentOrStaging } from 'utilities/checkCurrentTier';

const useAuthentication = (updateCurrentUserInfo, logLevel = 'INFO', registerEvents = false) => {
    const [debugLoggingEnabled, setDebugLoggingEnabled] = useState(true);
    const [hasQueriedUserInfo, setHasQueriedUserInfo] = useState(false);
    const [isAppAuthSequenceComplete, setIsAppAuthSequenceComplete] = useState(false);
    const [redirectStateData, setRedirectStateData] = useState(null);
    const [sessionInfo, setSessionInfo] = useState(null);
    const [userManager, setUserManager] = useState(null);
    const [wasAuthRedirectCalled, setWasAuthRedirectCalled] = useState(false);
    const [wasUserManagerEventsloaded, setWasUserManagerEventsloaded] = useState(false);

    // #region METHODS
    // *****************************************************************************************
    const buildUserData = (retrievedUser) => {
        const expired = retrievedUser ? retrievedUser.expired : true;
        const expiresIn = retrievedUser ? retrievedUser.expiresIn : -1;
        const fullUserDetails = { ...retrievedUser, expired, expiresIn };

        return fullUserDetails;
    };

    const checkForAuthRedirect = () => {
        const queryStringParams = queryString.parse(window.location.search);

        if (queryStringParams.redirectType === 'signin') {
            signinRedirectCallback();
        }
    };

    const getUserSession = async (calledFrom) => {
        if (userManager) {
            const retrievedUser = await userManager.getUser();

            if (retrievedUser) {
                const fullUserDetails = buildUserData(retrievedUser);
                saveUserData(fullUserDetails);
            }
            setHasQueriedUserInfo(true); // this gets set true after first getUser() query
        }
    };

    const login = async (redirectPath) => {
        await userManager.signinRedirect({ data: { startupPath: redirectPath } });
    };

    const logout = async () => {
        window.history.pushState(null, document.title, window.location.href);
        window.onpopstate = () => {
            login();
        };

        await userManager.signoutRedirect();
    };

    const saveUserData = (userData) => {
        if (debugLoggingEnabled) {
            console.log(
                `%c[useAuthentication HOOK] updating session obj w/ useState ${JSON.stringify(userData)}`,
                'color: #f97c00'
            );
        }
        setSessionInfo((previousSessionState) => userData);
    };

    const signinRedirectCallback = () => {
        if (debugLoggingEnabled) {
            console.log(`%c[useAuthentication HOOK] signinRedirectCallback() called`, 'color: #f97c00');
        }
        setWasAuthRedirectCalled(true);

        if (userManager) {
            userManager
                .signinRedirectCallback()
                .then((user) => {
                    setRedirectStateData(user.state);

                    const fullUserDetails = buildUserData(user);
                    saveUserData(fullUserDetails);
                })
                .catch((error) => {
                    if (error.error === 'access_denied') {
                        window.location.replace(process.env.REACT_APP_OAUTH_UNAUTHORIZED_URL);
                    }
                });
        }
    };
    // #endregion

    // #region INITIALIZE LOGIC
    // ***************************************************************
    const initializeEvents = (manager) => {
        manager.events.addUserLoaded((user) => {
            console.log(JSON.stringify(user));
            if (debugLoggingEnabled) {
                console.log('%c[useAuthentication HOOK] manager event: user loaded', 'color: #f97c00');
            } // https://dev.azure.com/AmericanAirlines/EmployeeDataServices/_git/epays3-ui

            localStorage.setItem('access_token', user.access_token);
            localStorage.setItem('id_token', user.id_token);
            localStorage.setItem('user_id', user.profile.sub);
            localStorage.setItem('full_name', `${user.profile.firstname} ${user.profile.lastname}`);
            localStorage.setItem('expiration_time', user.expires_at);
            localStorage.setItem('expired', user.expired);

            const newUser = {
                fullName: `${user.profile.firstname} ${user.profile.lastname}`,
                employeeId: user.profile.sub,
            };
            updateCurrentUserInfo(newUser);
            setWasUserManagerEventsloaded(true);
        });
    };

    const initializeLogLevel = () => {
        Log.logger = console;

        switch (logLevel.toUpperCase()) {
            case 'DEBUG':
                Log.level = Log.DEBUG;
                break;
            case 'INFO':
                Log.level = Log.INFO;
                break;
            case 'WARN':
                Log.level = Log.WARN;
                break;
            case 'ERROR':
                Log.level = Log.ERROR;
                break;
            default:
                Log.level = Log.NONE;
        }
    };

    const initializeManager = () => {
        const settings = {
            authority: process.env.REACT_APP_OAUTH_AUTHORITY_ROOT,
            client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
            redirect_uri: `${process.env.REACT_APP_OAUTH_CLIENT_ROOT}/authredirect?redirectType=signin`,
            silent_redirect_uri: `${process.env.REACT_APP_OAUTH_CLIENT_ROOT}/authredirect?redirectType=renew`,
            post_logout_redirect_uri: `${process.env.REACT_APP_OAUTH_CLIENT_ROOT}`,
            response_type: 'code',
            response_mode: 'query',
            scope: 'openid profile email',
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            metadata: {
                issuer: process.env.REACT_APP_OAUTH_AUTHORITY_ROOT,
                authorization_endpoint: `${process.env.REACT_APP_OAUTH_AUTHORITY_ROOT}/as/authorization.oauth2`,
                userinfo_endpoint: `${process.env.REACT_APP_OAUTH_AUTHORITY_ROOT}/idp/userinfo.openid`,
                jwks_uri: `${process.env.REACT_APP_OAUTH_AUTHORITY_ROOT}/pf/JWKS`,
                token_endpoint: `${process.env.REACT_APP_OAUTH_AUTHORITY_ROOT}/as/token.oauth2`,
                end_session_endpoint: `${process.env.REACT_APP_OAUTH_AUTHORITY_ROOT}/idp/startSLO.ping?TargetResource=${process.env.REACT_APP_OAUTH_LOGOUT_URL}`,
            },
        };
        const manager = new UserManager(settings);

        initializeEvents(manager);
        initializeLogLevel();
        setUserManager(manager);
    };

    // userManager should only change once - from initial value of null to oidc manager.
    useEffect(() => {
        if (userManager) {
            checkForAuthRedirect();
            getUserSession();
        }
    }, [userManager]);
    // #endregion

    useEffect(() => {
        initializeManager(logLevel, registerEvents);
        if (isTierDevelopmentOrStaging) {
            const enableDebugLogging = logLevel === 'DEBUG';
            setDebugLoggingEnabled(enableDebugLogging);
        }
    }, []);

    useEffect(() => {
        if (debugLoggingEnabled) {
            console.log(
                `%c[useAuthentication HOOK] detecting if signin process is complete:
                hasQueriedUserInfo --> ${hasQueriedUserInfo}, sessionInfo --> ${sessionInfo}, wasAuthRedirectCalled --> ${wasAuthRedirectCalled}, wasUserManagerEventsloaded --> ${wasUserManagerEventsloaded}`,
                'color: #f97c00'
            );
        }

        if (hasQueriedUserInfo && sessionInfo && wasAuthRedirectCalled && wasUserManagerEventsloaded) {
            setIsAppAuthSequenceComplete(true);
        }
    }, [hasQueriedUserInfo, sessionInfo, wasAuthRedirectCalled, wasUserManagerEventsloaded, debugLoggingEnabled]);

    // #region PUBLIC STATE & API
    //****************************************************************************
    const state = {
        isAppAuthSequenceComplete,
        isManagerReadyWithUserData: !!userManager && !!hasQueriedUserInfo,
        redirectRoute: redirectStateData ? redirectStateData.startupPath : '',
        sessionInfo,
        wasAuthRedirectCalled,
    };

    const api = {
        getUserSession,
        login,
        logout,
    };
    // #endregion

    return [state, api];
};

export default useAuthentication;