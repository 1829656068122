const routesExternal = [
    {
        title: 'State Form W-4',
        path: 'https://jetnet.aa.com/sites/team-member-services/knowledgebase/286899/federal-state-and-local-forms-and-policies?source=286894&sort=sortableNodeName&dir=asc&mode=list&ref=contextual_parentID--Equals--286848--Tax%20forms',
        showInNavMenu: true,
    },
    {
        title: 'Online W-2',
        path: 'https://jetnet.aa.com/sites/team-member-services/knowledgearticle/290290/accessing-your-w-2-forms',
        showInNavMenu: true,
    },
    {
        title: 'Employment Verification',
        path: 'https://theworknumber.com/',
        showInNavMenu: true,
    },
];

export default routesExternal;