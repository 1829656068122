import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AppDiagnosticsPage = lazy(() =>
    import('components/AppDiagnosticsPage/AppDiagnosticsPage')
);
const AuthRedirect = lazy(() =>
    import('components/AuthRedirect/AuthRedirect')
);
const DetailReportsContainer = lazy(() =>
    import('components/PayStatements/DetailReport/DetailReportsContainer')
);
const DirectDeposit = lazy(() =>
    import('components/DirectDeposit/DirectDeposit')
);
const ExpensesContainer = lazy(() =>
    import('components/PayStatements/ExpensePayments/ExpensesContainer')
);
const FederalW4 = lazy(() =>
    import('components/W4/FederalW4/FederalW4')
);
const PageNotFound = lazy(() =>
    import('components/PageNotFound/PageNotFound')
);
const PaychecksContainer = lazy(() =>
    import('components/PayStatements/Paychecks/PaychecksContainer')
);
const PayStatementsContainer = lazy(() =>
    import('components/PayStatements/PayStatementsContainer')
);
const StateW4 = lazy(() =>
    import('components/W4/StateW4/StateW4')
);

const routes = [
    {
        path: '/',
        element: <Navigate to='paystatements/paychecks' />,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        title: 'Pay Statements',
        path: '/paystatements',
        element: <PayStatementsContainer />,
        showInNavMenu: true,
        subRoutes: [
            {
                index: true,
                element: <Navigate to='paychecks' replace />,
            },
            {
                title: 'Paychecks',
                path: 'paychecks', 
                element: <PaychecksContainer />,
                isTab: true,
            },
            {
                title: 'Activity/Details Reports',
                path: 'activity-details-reports', 
                element: <DetailReportsContainer />,
                isTab: true,
            },
            {
                title: 'Expense Payments',
                path: 'expense-payments', 
                element: <ExpensesContainer />,
                isTab: true,
            },
            {
                title: 'Page Not Found',
                path: '*',
                element: <PageNotFound />,
            },
            {
                title: 'Paperless Pay (Historical LUS)',
                path: 'https://paperlesspay.talx.com/americanairlines/', 
                showInNavMenu: true,
            },
        ],
    },
    {
        title: 'Direct Deposit',
        path: '/direct-deposit',
        element: <DirectDeposit />,
        showInNavMenu: true,
        subRoutes: [],
    },
    {
        title: 'Federal Form W-4',
        path: '/w4-federal',
        element: <FederalW4 />,
        showInNavMenu: true,
        subRoutes: [],
    },
    {
        title: 'AppDiagnosticsPage',
        path: '/epays-adp',
        element: <AppDiagnosticsPage />,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        title: 'AuthRedirect',
        path: '/authredirect',
        element: <AuthRedirect />,
        showInNavMenu: false,
        subRoutes: [],
    },
    {
        title: 'Page Not Found',
        path: '*',
        element: <PageNotFound />,
        showInNavMenu: false,
        subRoutes: [],
    },
];

export default routes;
